<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">后台管理系统</div>
      <el-form :model="param" :rules="rules" ref="logins" label-width="0px" class="ms-content">
        <el-form-item prop="username">
          <el-input v-model="param.username" placeholder="user" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="password"
              v-model="param.password"
              @keyup.enter.native="submitForm"
          ></el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm">登录</el-button>
        </div>
        <p class="login-tips">Tips :输入用户名和密码。</p>
      </el-form>
    </div>
  </div>
</template>
<script>
import { login } from '@/api/sys';

export default {
  data() {
    return {
      param: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [ { required: true, message: "请输入密码", trigger: "blur" } ]
      },
      LandingLocation: ''
    };
  },
  methods: {
    submitForm() {
      this.$refs.logins.validate( async valid => {
        if ( valid ) {
          const { data, code, msg } = await login( this.param );

          if ( code !== 200 ) return this.$message.error( msg );

          this.$message.success( "登录成功" );

          await this.$router.push( { path: "/sys" } );

        }
      } );
    },
  },


  created() {

  }
};
</script>

<style scoped>

.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/Bg.jpg");
  background-size: 100%;
}

.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}

.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}

</style>


<style>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

</style>
